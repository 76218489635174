/*
 * index.js
 * ---------------
 * Component for the index page.
 */

import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import SiteMetadata from "../components/site-metadata";
import Layout from "../components/layout";
import Masthead from "../components/pages/index/masthead";
import About from "../components/pages/index/about";
import ChiaviInMano from "../components/pages/index/chiavi-in-mano";
import Products from "../components/pages/index/products";
import imagePublicPath from "../assets/images/pages/index/relaxing-woman.jpg";
import { useTranslation } from "../hooks/useTranslation";
import { useIntl } from "../hooks/useIntl";


const IndexPage = ({
  location,
  pageContext: {
    lang: pageLang,
    pageLangVariants,
  },
}) => {
  const { lang, setLang, setPageLangVariants } = useIntl();

  useEffect(() => {
    if (lang !== pageLang) {
      setLang(pageLang);
    }

    setPageLangVariants(pageLangVariants);
  }, [lang, pageLang, setLang, setPageLangVariants, pageLangVariants]);

  const tPage = useTranslation("pages.index");

  const metadata = useMemo(() => ({
    title: tPage("text.title"),
    description: tPage("text.description"),
    imagePublicPath,
  }), [tPage]);

  return (
    <Layout headerTakesSpace={false}>
      <SiteMetadata
        location={location}
        {...metadata}
      />

      <Masthead metadata={metadata} />
      <About />
      <ChiaviInMano />
      <Products />
    </Layout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;
