/*
 * background-image.js
 * -------------------
 * Exports the background-image component.
 */

import React from "react";
import Img from "gatsby-image";

import styles from "./background-image.module.scss";

const BackgroundImage = (props) => (
  <div className={styles.imageWrapper}>
    <Img
      style={{ height: "100%" }}
      {...props}
    />
  </div>
);

export default BackgroundImage;
