/*
 * chiavi-in-mano.js
 * -----------------
 * Exports the ChiaviInMano component.
 */

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "../../../hooks/useTranslation";
import { useIntl } from "../../../hooks/useIntl";
import markdownPagePaths from "../../../markdownPagePaths.json";
import BackgroundImage from "../../background-image";
import Button from "../../button";
import Overlay from "../../overlay";
import styles from "./chiavi-in-mano.module.scss";

const ChiaviInMano = () => {
  const { lang } = useIntl();
  const t = useTranslation();
  const tComponent = useTranslation("pages.index.components.ChiaviInMano");

  const {
    file: {
      childImageSharp: {
        fluid,
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/pages/index/home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className={styles.container}>
      <BackgroundImage
        fluid={fluid}
        alt={tComponent("text.imageAltText")}
        imgStyle={{ objectPosition: "center right" }}
      />

      <Overlay alpha={0.25} />

      <div className={styles.content}>
        <div className={styles.contentText}>
          <h2 className={styles.header}>{tComponent("text.title")}</h2>
          <p className={styles.subheader}>{tComponent("text.subtitle")}</p>
        </div>

        <div className={styles.contentLinks}>
          <Button
            to={markdownPagePaths["chiavi-in-mano"][lang]}
            type="contained"
            color="secondary"
            size="medium"
            className={styles.contentLink}
          >
            {t("text.learnMore")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChiaviInMano;
