/*
 * about.js
 * --------
 * Exports the about component.
 */

import React from "react";
import LogoSymbol from "../../../assets/images/logo-symbol.svg";
import LongParagraph from "../../long-paragraph";
import Button from "../../button";
import { useTranslation } from "../../../hooks/useTranslation";
import { useIntl } from "../../../hooks/useIntl";
import markdownPagePaths from "../../../markdownPagePaths.json";
import styles from "./about.module.scss";

const About = () => {
  const { lang } = useIntl();
  const t = useTranslation();
  const tComponent = useTranslation("pages.index.components.About");

  return (
    <div className={styles.container} id={tComponent("ids.about")}>
      <div className={styles.content}>
        <LogoSymbol className={styles.logo} />

        <div className={styles.spacer} />

        <LongParagraph className={styles.text}>
          {tComponent("text.about")}
        </LongParagraph>
      </div>

      <div className={styles.infoButtonContainer}>
        <Button
          to={markdownPagePaths.azienda[lang]}
          type="outlined"
          color="secondary"
          size="medium"
        >
          {t("text.learnMore")}
        </Button>
      </div>
    </div>
  );
};

export default About;
