/*
 * masthead.js
 * -----------
 * Exports the masthead component.
 */

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "../../../hooks/useTranslation";
import BackgroundImage from "../../background-image";
import Overlay from "../../overlay";
import styles from "./masthead.module.scss";

const Masthead = () => {
  const tComponent = useTranslation("pages.index.components.Masthead");

  const {
    file: {
      childImageSharp: {
        fluid,
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/pages/index/relaxing-woman.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className={styles.container}>
      <BackgroundImage
        fluid={fluid}
        alt={tComponent("text.imageAltText")}
      />

      <Overlay alpha={0.2} />

      <div className={styles.content}>
        <div className={styles.contentText}>
          <h1 className={styles.header}>{tComponent("text.title")}</h1>
          <p className={styles.subheader}>{tComponent("text.subtitle")}</p>
        </div>
      </div>
    </div>
  );
};

export default Masthead;
