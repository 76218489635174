/*
 * button.js
 * ---------
 * Exports the button component.
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import styles from "./button.module.scss";

const Button = ({
  type, to, color, light, size, className, children,
}) => {
  const classes = [
    styles.button, styles[type], styles[color], styles[size],
    light ? styles.light : "",
    className,
  ];

  if (to) classes.push(styles.link);

  return to ? (
    <Link
      to={to}
      className={classes.join(" ")}
    >
      {children}
    </Link>
  ) : (
    <button
      className={classes.join(" ")}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  to: "",
  color: "primary",
  light: false,
  size: "medium",
  className: "",
};

Button.propTypes = {
  type: PropTypes.oneOf(["text", "outlined", "contained"]).isRequired,
  to: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary"]),
  light: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "large"]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Button;
