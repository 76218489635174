/*
 * products.js
 * -----------
 * Exports the products component.
 */

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "../../../hooks/useTranslation";
import { useIntl } from "../../../hooks/useIntl";
import markdownPagePaths from "../../../markdownPagePaths.json";
import ProductCard from "./product-card";
import styles from "./products.module.scss";

const query = graphql`
  query {
    serramenti: file(relativePath: { eq: "images/pages/index/serramenti.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tendeInterne: file(relativePath: { eq: "images/pages/index/tendeInterne.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pavimentiInLegno: file(relativePath: { eq: "images/pages/index/pavimentiInLegno.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    soppalchi: file(relativePath: { eq: "images/pages/index/soppalchi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    porte: file(relativePath: { eq: "images/pages/index/porte.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    piastrelle: file(relativePath: { eq: "images/pages/index/piastrelle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    materassi: file(relativePath: { eq: "images/pages/index/materassi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    arrediCompleti: file(relativePath: { eq: "images/pages/index/arrediCompleti.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    arredobagno: file(relativePath: { eq: "images/pages/index/arredobagno.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    opereInCartongesso: file(relativePath: { eq: "images/pages/index/opereInCartongesso.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contract: file(relativePath: { eq: "images/pages/index/contract.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pergoleBioclimatiche: file(relativePath: { eq: "images/pages/index/pergoleBioclimatiche.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Products = () => {
  const images = useStaticQuery(query);
  const { lang } = useIntl();
  const tComponent = useTranslation("pages.index.components.Products");

  const products = [
    {
      title: tComponent("text.serramentiTitle"),
      description: tComponent("text.serramentiDescription"),
      image: images.serramenti.childImageSharp.fluid,
      link: markdownPagePaths.serramenti[lang],
    },
    {
      title: tComponent("text.tendeInterneTitle"),
      description: tComponent("text.tendeInterneDescription"),
      image: images.tendeInterne.childImageSharp.fluid,
      link: markdownPagePaths["tende-interne"][lang],
    },
    {
      title: tComponent("text.pergoleBioclimaticheTitle"),
      description: tComponent("text.pergoleBioclimaticheDescription"),
      image: images.pergoleBioclimatiche.childImageSharp.fluid,
      link: markdownPagePaths["pergole-bioclimatiche"][lang],
    },
    {
      title: tComponent("text.pavimentiInLegnoTitle"),
      description: tComponent("text.pavimentiInLegnoDescription"),
      image: images.pavimentiInLegno.childImageSharp.fluid,
      link: markdownPagePaths["pavimenti-in-legno"][lang],
    },
    {
      title: tComponent("text.porteTitle"),
      description: tComponent("text.porteDescription"),
      image: images.porte.childImageSharp.fluid,
      link: markdownPagePaths.porte[lang],
    },
    {
      title: tComponent("text.piastrelleTitle"),
      description: tComponent("text.piastrelleDescription"),
      image: images.piastrelle.childImageSharp.fluid,
      link: markdownPagePaths.piastrelle[lang],
    },
    {
      title: tComponent("text.materassiTitle"),
      description: tComponent("text.materassiDescription"),
      image: images.materassi.childImageSharp.fluid,
      link: markdownPagePaths.materassi[lang],
    },
    {
      title: tComponent("text.arrediCompletiTitle"),
      description: tComponent("text.arrediCompletiDescription"),
      image: images.arrediCompleti.childImageSharp.fluid,
      link: markdownPagePaths["arredi-completi"][lang],
    },
    {
      title: tComponent("text.arredoBagnoTitle"),
      description: tComponent("text.arredoBagnoDescription"),
      image: images.arredobagno.childImageSharp.fluid,
      link: markdownPagePaths.arredobagno[lang],
    },
    {
      title: tComponent("text.soppalchiTitle"),
      description: tComponent("text.soppalchiDescription"),
      image: images.soppalchi.childImageSharp.fluid,
      link: markdownPagePaths.soppalchi[lang],
    },
    {
      title: tComponent("text.opereInCartongessoTitle"),
      description: tComponent("text.opereInCartongessoDescription"),
      image: images.opereInCartongesso.childImageSharp.fluid,
      link: markdownPagePaths["opere-in-cartongesso"][lang],
    },
    {
      title: tComponent("text.contractTitle"),
      description: tComponent("text.contractDescription"),
      image: images.contract.childImageSharp.fluid,
      link: markdownPagePaths.contract[lang],
    },
  ];

  return (
    <div className={styles.content}>
      <h2 className={styles.heading}>
        {tComponent("text.title")}
      </h2>
      <h3 className={styles.subheader}>
        {tComponent("text.subtitle")}
      </h3>

      <div className={styles.productCards}>
        {products.map((product) => (
          <ProductCard key={product.title} product={product} />
        ))}
      </div>
    </div>
  );
};

export default Products;
