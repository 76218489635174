/*
 * overlay.js
 * ----------
 * Exports the overlay component.
 */

import React from "react";
import PropTypes from "prop-types";

import styles from "./overlay.module.scss";

const Overlay = ({ color, alpha }) => (
  <div
    style={{ backgroundColor: color, opacity: alpha }}
    className={styles.overlay}
  />
);

Overlay.defaultProps = {
  color: "#000",
  alpha: 0.33,
};

Overlay.propTypes = {
  color: PropTypes.string,
  alpha: PropTypes.number,
};

export default Overlay;
