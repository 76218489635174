/*
 * long-paragraph.js
 * -----------------
 * Exports the long-paragraph component.
 */

import React from "react";
import PropTypes from "prop-types";

import styles from "./long-paragraph.module.scss";

const LongParagraph = ({ children, className, ...props }) => (
  <p className={`${styles.paragraph} ${className}`} {...props}>
    {children}
  </p>
);

LongParagraph.defaultProps = {
  className: "",
};

LongParagraph.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default LongParagraph;
