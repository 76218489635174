/*
 * product-card.js
 * ---------------
 * Exports the product-card component.
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styles from "./product-card.module.scss";

const ProductCard = ({ product }) => (
  <div className={styles.container}>
    <Link to={product.link} className={styles.card}>
      <div className={styles.imageContainer}>
        <Img
          fluid={product.image}
          alt={product.title}
          className={styles.image}
          style={{ position: "absolute" }}
        />
      </div>

      <div className={styles.cardText}>
        <h3 className={styles.cardTitle}>{product.title}</h3>

        <p className={styles.cardDescription}>{product.description}</p>
      </div>
    </Link>
  </div>
);

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductCard;
